import axios from "axios";
import { BASE_URL } from "../../config";

//  Function to call the api to validate the user credentials
export const login = async (values) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/api/login`, values);
    return data;
  } catch (error) {
    console.log(error);
    throw error.response.data.error;
  }
};

// Function to call the api to validate the otp after successful login

export const otpCheck = async (info) => {
  try {
    const { data } = await axios.post(
      `${BASE_URL}/api/otpcheck`,

      info
    );
    return data;
  } catch (error) {
    throw error.response.data.error;
  }
};
