import React from "react";
import { getRoleFromToken } from "../../services/searchPageApis/api";

const PastElectionsReport = () => {
  const role = getRoleFromToken();
  const iframeSrc =
    role === "Demo"
      ? "https://lookerstudio.google.com/embed/reporting/15a09eb2-fa12-4e82-93cd-a3faf4f0b4ea/page/kyhkD"
      : "https://lookerstudio.google.com/embed/reporting/791cef3c-0087-404a-a182-bc0fc93222c7/page/kyhkD";

  return (
    <>
      <div className="container-fluid mt-3" style={{ height: "130vh" }}>
        <iframe
          width="100%"
          height="100%"
          src={iframeSrc}
          frameBorder="10"
          style={{
            border: "3px solid #ccc",
            borderRadius: "10px",
          }}
          allowFullScreen
          title="Dashboard"
        ></iframe>
      </div>
    </>
  );
};
export default PastElectionsReport;
