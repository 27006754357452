import axios from "axios";
import { categoryMapping } from "../../data/searchpage/data";
import Cookies from "js-cookie";
import { BASE_URL, demotableName, personsinfoTableName } from "../../config";
import { getRoleFromToken } from "../searchPageApis/api";

export const FetchingCategoryDetails = async (
  linkedin_id,
  decodedID,
  category
) => {
  const role = getRoleFromToken();

  const data = {
    linkedin_id: linkedin_id,
    id_master: decodedID,
    category: categoryMapping[category],
    tableName:
      role === "Demo_general" || role === "Demo"
        ? demotableName
        : personsinfoTableName,
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/api/category`,

      data,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("ElectionToken")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
//////////////////////////////////////////////////////////////////////////////
export const ProfileHeaderData = async (decodedID) => {
  const role = getRoleFromToken();
  const tableName =
    role === "Demo_general" || role === "Demo"
      ? demotableName
      : personsinfoTableName;
  const url = `${BASE_URL}/api/userprofile/${decodedID}?tableName=${tableName}`;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${Cookies.get("ElectionToken")}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
////////////////////////////////////////////////////////////////////////////////
export const FetchingUserFamilyData = async (uid) => {
  const role = getRoleFromToken();
  const tableName =
    role === "Demo_general" || role === "Demo"
      ? demotableName
      : personsinfoTableName;
  try {
    const response = await axios.get(
      `${BASE_URL}/api/userfamily/${uid}?tableName=${tableName}`,

      {
        headers: {
          Authorization: `Bearer ${Cookies.get("ElectionToken")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
