import vantiqaLogo from "../../images/Vantiqa logos-02@2x.png";
import { Link } from "react-router-dom";
import UserContext from "../Context/userContext";
import React, { useEffect, useState, useContext } from "react";
import "../styles/navbar.css";
import PersonIcon from "@mui/icons-material/Person";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import Cookies from "js-cookie";
import "../styles/index.css";

import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function NavBar() {
  const [show, setShow] = useState(false);
  const { userRole, setUserRole } = useContext(UserContext);
  const history = useHistory();

  const logout = () => {
    Cookies.remove("ElectionToken");
    setUserRole(null);
    history.push("/login");
  };

  const redirectToSearchPage = () => {
    history.push("/");
  };

  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();

  useEffect(() => {
    setShow(location.pathname !== "/login");
    setActiveLink(location.pathname);
  }, [location.pathname]);

  return (
    show && (
      <nav className="flex items-center justify-between p-0 bg-black text-white">
        <div
          className="flex items-center cursor-pointer h-1 px-3 fw-bold"
          onClick={redirectToSearchPage}
        >
          Field Analytics
          {/* <img src={vantiqaLogo} alt="Vantiqa Logo" className="h-6 px-3" /> */}
        </div>
        <div className="flex space-x-8">
          <Link
            to="/"
            className={`mx-6 py-2 rounded text-white no-underline ${
              activeLink === "/" ? "bg-black" : "hover:bg-gray-700"
            }`}
          >
            <PersonPinOutlinedIcon className="mr-2" /> Voter Profiles
          </Link>
          <Link
            to="/dashboard"
            className={`mx-6 py-2 rounded text-white no-underline ${
              activeLink === "/dashboard" ? "bg-black" : "hover:bg-gray-700"
            }`}
          >
            <DashboardIcon className="mr-2" /> Campaign Dashboard
          </Link>
          <Link
            to="/users"
            className={`mx-6 py-2 rounded text-white no-underline ${
              activeLink === "/users" ? "bg-black" : "hover:bg-gray-700"
            }`}
          >
            <PeopleOutlinedIcon className="mr-2" /> Admin Panel
          </Link>
          <button
            onClick={logout}
            className="px-3 py-2 rounded text-red-500 hover:bg-gray-700"
          >
            <ExitToAppIcon className="mr-2" /> Logout
          </button>
        </div>
      </nav>
    )
  );
}
export default NavBar;
